<h1>Mes achats</h1>
<table class="table text-white">
    <thead>
        <tr>
            <th>n° Code Boutique</th>
            <th class="text-center">Date</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let order of orders$ | async">
            <td>{{ order.shipping.name }}</td>
            <td class="text-center">
                {{ order.datePlaced | date:'medium'}}
            </td>
            <td>
                <button mat-button (click)="openSnackbar()">View</button>
            </td>
        </tr>
    </tbody>
</table>