export const environment = {
    production: true,
    firebase: {
        apiKey: "AIzaSyB3Qh3CFSEWfm_YSuC5dB4kKYsY8hEblyk",
  authDomain: "gameshop-gta.firebaseapp.com",
  databaseURL: "https://gameshop-gta.firebaseio.com",
  projectId: "gameshop-gta",
  storageBucket: "gameshop-gta.appspot.com",
  messagingSenderId: "167663251972",
  appId: "1:167663251972:web:9fa705e5a6b8c489ef3b51",
  measurementId: "G-WLHF9N0RSK"
    }
};