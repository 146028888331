<div class="row no-gutters">
    <div class="col text-center">
        <button mat-raised-button [matMenuTriggerFor]="menu">
            {{ shoppingCart.getQuantity(product) }}
        </button>
    </div>
    <mat-menu #menu="matMenu" yPosition="above">
        <button mat-menu-item (click)="addToCart()">
            Augmenter
        </button>
        <button mat-menu-item (click)="removeFromCart()">
            Diminuer
        </button>
    </mat-menu>

</div>