import { ShoppingCart } from 'shared/models/shopping-cart';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'shared/services/auth.service';
import { OrderService } from 'shared/services/order.service';
import { ProductService } from 'shared/services/product.service';
import { Order } from 'shared/models/order';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
// import { Http } from '@angular/http';

@Component({
  selector: 'shipping-form',
  templateUrl: './shipping-form.component.html',
  styleUrls: ['./shipping-form.component.css'],
})
export class ShippingFormComponent implements OnInit, OnDestroy {
  @Input('cart') cart: ShoppingCart;
  
  // products: Product[];
  // filteredProducts: any[];
  // subscription: Subscription;

  // dataSource: MatTableDataSource<Product>;
  // displayedColumns: string[] = ['title', 'price', 'edit', 'delete'];
  // pageSizeOptions: number[] = [3, 5, 10, 25, 100];
  // length: number;
  // pageSize: number = 5;

  // @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator;

  
  public payPalConfig ?: IPayPalConfig;

  shipping: any = {};
  // userSubscription: Subscription;
  // userId: string;

  constructor(
    private authService: AuthService,
    private orderService: OrderService,
    private router: Router,
	private http: HttpClient,
	private productService: ProductService
  ) {
	// this.subscription = this.productService.getAll().subscribe((products) => {
      // this.filteredProducts = this.products = products.map((product) => {
        // return <Product>{
          // key: product.key,
          // title: product.val['title'],
          // category: product.val['category'],
          // price: product.val['price'],
          // imageUrl: product.val['imageUrl'],
        // };
      // });

      // this.dataSource = new MatTableDataSource(this.products);
      // this.dataSource.sort = this.sort;
      // this.dataSource.paginator = this.paginator;
      // this.length = this.products.length;
    // });
  }
  
  // delete(key) {
    // if (!confirm('Are you sure you want to delete this product?')) return;

    // this.productService.delete(key);
  // }
  ngOnInit() {
    // this.userSubscription = this.authService.user$.subscribe(
      // (user) => (this.userId = user.uid)
    // );
	// console.log(this.productService.get(this.cart.items[0].key));
	// console.log(this.productService.getAll());
	// console.log(this.cart.items[0]);
	//this.productService.delete(this.cart.items[0].key);
	
	this.initConfig();
	
  }
  var 
  private initConfig(): void {
	  // console.log(this.productService.get(this.cart.items[0].key));
	  // console.log(this.cart.items[0].key);
	  // console.log(this.productService.getAll());
    this.payPalConfig = {
      currency: 'EUR',
      clientId: 'AbSwLn9JwawtcS3Art-dVWeLRGKHZi6jZWI2tvhRsmTpwpbAo0rsukImr-NawDZdEJmvDoBEq90w4_X3', // add paypal clientId here
      createOrderOnClient: (data) => <ICreateOrderRequest> {
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: 'EUR',
            value: `${this.cart.totalPrice}`,
            breakdown: {
              item_total: {
                currency_code: 'EUR',
                value: `${this.cart.totalPrice}`
              }
            }
          },
          items: [{
            name: '🚀 Five World',
            quantity: '1',
            category: 'DIGITAL_GOODS',
            unit_amount: {
              currency_code: 'EUR',
              value: `${this.cart.totalPrice}`,
            },
          }]
        }]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
        size: 'small',
        color: 'blue',
        shape: 'rect'
      },
      onApprove: (data, actions) => {
        // console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          // console.log('onApprove - you can get full order details inside onApprove: ', details);
		  actions.order.capture().then(details => {
          // console.log('onApprove - you can get full order details inside onApprove: ', details);
		  
        });
        });

      },
      onClientAuthorization: (data) => {
        // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
		this.placeOrder();
      },
      onCancel: (data, actions) => {
        // console.log('OnCancel', data, actions);
      },
      onError: err => {
        // console.log('OnError', err);
      },
      onClick: (data, actions) => {
        // console.log('onClick', data, actions);
      }
    };
  }

  async placeOrder() {
    let order = new Order("", this.shipping, this.cart);
	this.productService.delete(this.cart.items[0].key);
    // let result = await this.orderService.placeOrder(order);
    this.router.navigate(['/order-success']);
	this.http.post<any>('https://esifly.fr/insertMocroBtq.php', order).subscribe({
        next: data => {
			// console.log(data);
        },
        error: error => {
            // this.errorMessage = error.message;
            console.error('There was an error!', error);
        }
    })
  }

  ngOnDestroy() {
    // this.userSubscription.unsubscribe();
  }
}
