<div class="row no-gutters">
    <div class="col-2">
        <button mat-icon-button color="warn" (click)="removeFromCart()" class="btn-block text-center">
            <mat-icon class="remove-item">remove_circle</mat-icon>
        </button>
    </div>
    <div class="col text-center items">
        {{ shoppingCart.getQuantity(product) }}
    </div>
    <div class="col-2 plus">
        <button mat-icon-button color="primary" (click)="addToCart()" class="btn-block text-center">
            <mat-icon class="add-item">add_circle</mat-icon>
        </button>
    </div>
</div>

<!-- <div class="row no-gutters">
    <div class="col text-center">
        <button mat-raised-button [matMenuTriggerFor]="menu">
            {{ shoppingCart.getQuantity(product) }} items
        </button>
    </div>
    <mat-menu #menu="matMenu" yPosition="above">
        <button mat-menu-item (click)="addToCart()">
            Add
        </button>
        <button mat-menu-item (click)="removeFromCart()">
            Remove
        </button>
    </mat-menu>

</div> -->