<!-- <bs-navbar></bs-navbar>


<main role="main" class="container">

  <router-outlet></router-outlet>

</main> -->

<mat-nav>

  <main role="main" class="container">
    
    <router-outlet></router-outlet>

  </main>

</mat-nav>