<h1 class="font-weight-bold">Panier</h1>

<div class="row col" *ngIf="cart$ | async as cart">
    <p>
        Vous avez {{ cart.totalItemsCount }} articles dans votre panier.
        <button mat-button *ngIf="cart.items.length" (click)="clearCart()">
            Effacer le panier
        </button>
    </p>

    <table class="table table-sm text-white">
        <thead>
            <tr>
                <th scope="col"></th>
                <th scope="col">Articles</th>
                <th scope="col" class="text-center" style="width: 15rem;">
                    Quantité
                </th>
                <th scope="col" class="text-right">
                    Prix
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of cart.items">
                <th scope="row">
                    <div [style.backgroundImage]="'url(' + item.imageUrl + ')'" class="thumbnail">
                    </div>
                </th>
                <td>
                    {{ item.title }}
                </td>
                <td>
                    <cart-product-quantity [product]="item" [shopping-cart]="cart">
                    </cart-product-quantity>
                </td>
                <td class="text-right">
                    {{ item.totalPrice |currency:'EUR' }}
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <th></th>
                <th></th>
                <th></th>
                <th class="text-right font-weight-bold">
                    {{ cart.totalPrice | currency:'EUR'}}
                </th>
            </tr>
        </tfoot>
    </table>

    <div class="actions">
        <button mat-raised-button routerLink="/check-out" *ngIf="cart.items.length" class="checkout">
            Valider
        </button>
    </div>
</div>