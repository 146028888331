import { switchMap } from 'rxjs/operators';
import { AuthService } from 'shared/services/auth.service';
import { OrderService } from 'shared/services/order.service';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-my-cgv',
  templateUrl: './my-cgv.component.html',
  styleUrls: ['./my-cgv.component.css'],
})
export class MyCgvComponent {
  // orders$;

  // constructor(
    // private authService: AuthService,
    // private orderService: OrderService,
    // private snackBar: MatSnackBar
  // ) {
    // this.orders$ = this.authService.user$.pipe(
      // switchMap((u) => this.orderService.getOrdersByUser(u.uid))
    // );
  // }

  // openSnackbar() {
    // this.snackBar.open('Articles dans votre menu Boutique en jeux!', `OK`, {
      // duration: 2000,
    // });
  // }
}
