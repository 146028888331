<!-- <p>
    <a routerLink="/admin/products/new" class="btn btn-primary">Add New Product</a>
</p>

<p>
    <input #query (keyup)="filter(query.value)" placeholder="Search for products..." class="form-control">
</p>

<table class="table table-hover">
    <caption>List of Products</caption>
    <thead>
        <tr>
            <th>Title</th>
            <th>Price</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let p of filteredProducts">
            <td>{{ p.title }}</td>
            <td>{{ p.price }}</td>
            <td>
                <a [routerLink]="['/admin/products/', p.key]">Edit</a>
            </td>
        </tr>
    </tbody>
</table> -->


<div class="row">
    <button class="btn-lg add-game" mat-raised-button color="primary" routerLink="/admin/products/new">
        Ajouter un nouvelle article
    </button>
</div>


<!-- <mat-form-field class="search-label">
    <mat-label>Search for Games</mat-label>
    <input matInput (keyup)="filter($event.target.value)" placeholder="Ex: Watchdogs 3" type="text">
</mat-form-field> -->

<div class="form-group search-label">
    <label for="">Rechercher des articles</label>
    <input (keyup)="filter($event.target.value)" placeholder="Ex: Diamants" class="form-control">
</div>

<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort class="mat-table">

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Title Column -->
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Titre </th>
            <td mat-cell *matCellDef="let element">
                {{element.title}}
            </td>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Prix </th>
            <td mat-cell *matCellDef="let element">
                {{element.price}}
            </td>
        </ng-container>

        <!-- Edit Column -->
        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef> Editer </th>
            <td mat-cell *matCellDef="let element">
                <button class="btn" [routerLink]="['/admin/products/', element.key]" mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> Supprimer </th>
            <td mat-cell *matCellDef="let element">
                <button class="btn" (click)="delete(element.key)" mat-icon-button color="warn">
                    <mat-icon>delete_outline</mat-icon>
                </button>
            </td>
        </ng-container>
    </table>
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
</div>