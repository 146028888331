<!-- <div class="card mx-auto">
    <div class="card-body">
        <h5 class="card-title">Order Summary</h5>
        <p class="card-text">
            You have {{ cart.totalItemsCount }} items in your shopping cart.
        </p>

        <ul class="list-group list-group-flush">
            <li *ngFor="let item of cart.items" class="list-group-item">
                {{ item.quantity }} x {{ item.title }} :
                <div class="float-right">
                    {{ item.totalPrice | currency:'INR' }}
                </div>
            </li>
            <li class="list-group-item font-weight-bold">
                Total
                <div class="float-right">
                    {{ cart.totalPrice | currency:'INR' }}
                </div>
            </li>
        </ul>
    </div>
</div> -->

<mat-card class="mx-auto main-card text-white">
    <mat-card-title>
        <h4 class="font-weight-bold">Récapitulatif de votre commande</h4>
    </mat-card-title>

    <mat-card-content>

        <p>
            Vous avez {{ cart.totalItemsCount }} articles dans votre panier.
        </p>

        <mat-list class="list-group list-group-flush text-white">
            <mat-list-item *ngFor="let item of cart.items ">
                <div class="text-white">
                    {{ item.quantity }} x {{ item.title }} :
                </div>
                <div class="f-right text-white">
                    {{ item.totalPrice | currency:'EUR' }}
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item class="font-weight-bold text-white">
                Total
                <div class="f-right">
                    {{ cart.totalPrice | currency:'EUR' }}
                </div>
            </mat-list-item>
        </mat-list>

    </mat-card-content>
</mat-card>